
export const getAPIURL = () => {

    // console.log("Welcome React");
    // console.log(process.env.NODE_ENV);
    var environment = process.env.NODE_ENV
    // console.log("Environment : " + environment);

    // const apiUrl = 'http://localhost/omniwheels/bb-campus-api/';
    // const apiUrl = 'https://api.campus-uat.suncolleges.ac.in/';
    const apiUrl = 'https://api.campus.suncolleges.ac.in/';

    return apiUrl;
}

export const getApiUrlForPayroll = () => {

    // const apiUrlForPayroll = 'https://api.payroll-dev.suncolleges.ac.in/';
    const apiUrlForPayroll = 'https://api.payroll.suncolleges.ac.in/';

    return apiUrlForPayroll;
}


// getLicenseKey
export const getLicenseKey = 5566;