import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toasts } from '../services/toast.service';
import { getProfileForStudent } from '../services/student.service';
import { getProfileForStaff } from '../services/staff.service';

export interface IAuthContextProps {
	userAccountId: any;
	userData: any;
	userTypeId: any;
	appAPK: any;
	isPayrollConnected: any;
	setUserAccountId?(...args: unknown[]): unknown;
	setUserData?(...args: unknown[]): unknown;
	setUserTypeId?(...args: unknown[]): unknown;
	setIsPayrollConnected?(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [userAccountId, setUserAccountId] = useState<string>(localStorage.getItem('userAccountId') || '');
	const [userTypeId, setUserTypeId] = useState<string>(localStorage.getItem('userTypeId') || '');
	const [userData, setUserData] = useState<any>({});
	const [appAPK, setAppAPK] = useState<any>('');
	const [isPayrollConnected, setIsPayrollConnected] = useState<any>(localStorage.getItem('isPayrollConnected') || false);

	useEffect(() => {
		localStorage.setItem('userAccountId', userAccountId);
	}, [userAccountId]);

	useEffect(() => {
		if (userAccountId !== '') {
			if (userTypeId != '10') {
				getStaffProfile(userAccountId);
			}
			else {
				getStudentProfile(userAccountId);
			}
		}
	}, [userAccountId]);

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile[0];
					if (data != undefined) {
						setUserData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffProfile(staffDetailsId: any) {
		getProfileForStaff(staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.profileForStaff[0];
					if (data != undefined) {
						setUserData(data);
						setAppAPK(data.configValue)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const value = useMemo(
		() => ({
			userAccountId,
			setUserAccountId,
			userData,
			userTypeId,
			setUserTypeId,
			appAPK,
			setIsPayrollConnected,
			isPayrollConnected
		}),
		[userAccountId, userTypeId, userData, isPayrollConnected],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;